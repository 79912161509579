import classNames from 'classnames';
import styles from './index.module.scss';
import {
  DefaultProps,
  Typography,
} from 'src/features/shared/components/typography/Typography';

interface TypographyComponentProps extends DefaultProps {
  component?: React.ElementType;
  defaultStyle?: string;
}

export function H1(props: TypographyComponentProps) {
  return <Typography component="h1" defaultStyle="h1" {...props} />;
}

export function H2(props: TypographyComponentProps) {
  return <Typography component="h2" defaultStyle="h2" {...props} />;
}

export function H3(props: TypographyComponentProps) {
  return <Typography component="h3" defaultStyle="h3" {...props} />;
}

export function H4(props: TypographyComponentProps) {
  return <Typography component="h4" defaultStyle="h4" {...props} />;
}

export function H5(props: TypographyComponentProps) {
  return <Typography component="h5" defaultStyle="h5" {...props} />;
}

export function Price(props: TypographyComponentProps) {
  return <Typography component="span" defaultStyle="price" {...props} />;
}

export function Button(props: TypographyComponentProps) {
  return <Typography component="span" defaultStyle="button" {...props} />;
}
export function Link(props: TypographyComponentProps) {
  return <Typography component="a" defaultStyle="link" {...props} />;
}

export function Body(props: TypographyComponentProps) {
  return <Typography component="p" defaultStyle="body" {...props} />;
}

export function Small(props: TypographyComponentProps) {
  return <Typography component="p" defaultStyle="small" {...props} />;
}

export function Large(props: TypographyComponentProps) {
  return <Typography component="p" defaultStyle="large" {...props} />;
}

export function Note(props: TypographyComponentProps) {
  return <Typography component="span" defaultStyle="note" {...props} />;
}

export function SectionHeading(props: TypographyComponentProps) {
  return (
    <Typography component="h3" defaultStyle="section-heading" {...props} />
  );
}

export function StretchedTitle(props: TypographyComponentProps) {
  const givenClassName = props.className;
  return (
    <ClubPageHeading
      aria-hidden="true"
      component="span"
      className={classNames(styles.stretchedTitle, givenClassName)}
      {...props}
    >
      {props.children}
    </ClubPageHeading>
  );
}

export function ClubPageHeading(props: TypographyComponentProps) {
  return (
    <Typography component="h1" defaultStyle="club-page-heading" {...props} />
  );
}

export function ClubPageSectionHeading(props: TypographyComponentProps) {
  return (
    <Typography
      component="h2"
      defaultStyle="club-page-section-heading"
      {...props}
    />
  );
}

/**
 * Add data-content to the title component
 */
export function SpecialHeading(props: TypographyComponentProps) {
  return (
    <Typography component="h1" defaultStyle="special-heading" {...props} />
  );
}
