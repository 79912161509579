'use client';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { Provider } from './context';

import messagesEn from 'translations/en.json';
import messagesNl from 'translations/nl.json';

const messages = {
  en: messagesEn,
  nl: messagesNl,
};

const formatMessageValues = {
  b: (value) => <b>{value}</b>,
  i: (value) => <i>{value}</i>,
  u: (value) => <u>{value}</u>,
  s: (value) => <s>{value}</s>,
};

const IntlProviderWrapper = ({ onError, children }) => {
  const locale = 'nl';
  const intlCache = useMemo(() => createIntlCache(), []);
  const intl = useMemo(() => {
    return createIntl(
      {
        locale,
        messages: messages[locale],
        textComponent: () => {},
        ...(onError ? { onError } : null),
      },
      intlCache,
    );
  }, [intlCache, locale, messages]);

  const intlState = useMemo(
    () => ({
      formatMessage: (id, values = {}) =>
        intl.formatMessage({ id }, { ...formatMessageValues, ...values }),
      formatDate: intl.formatDate,
      formatTime: intl.formatTime,
      formatRelativeTime: intl.formatRelativeTime,
      formatNumber: intl.formatNumber,
      formatNumberToParts: intl.formatNumberToParts,
      formatPlural: intl.formatPlural,
      formatCurrency: (value, currency) =>
        intl.formatNumber(value, { style: 'currency', currency }),
    }),
    [intl],
  );

  return <Provider value={intlState}>{children}</Provider>;
};
IntlProviderWrapper.propTypes = {
  children: PropTypes.node,
  onError: PropTypes.func,
};
IntlProviderWrapper.defaultProps = {
  children: null,
  onError: undefined,
};

export default IntlProviderWrapper;
