import classNames from 'classnames';
import styles from './index.module.scss';
import React from 'react';

export interface DefaultProps {
  children: React.ReactNode;
  className?: string;
  typographyStyle?: string;
  hasLineThrough?: boolean;
  isCentered?: boolean;
  fontWeight?: (typeof FontWeight)[keyof typeof FontWeight] | boolean;
  color?: (typeof FontColor)[keyof typeof FontColor] | boolean;
  isSmall?: boolean;
  hasBorderTop?: boolean;
  hasBorder?: boolean;
  hasError?: boolean;
  hasSuccess?: boolean;
  hasWordBreak?: boolean;
  forceMobileView?: boolean;
}

interface TypographyProps extends DefaultProps {
  component: React.ElementType;
  defaultStyle: string;
}

export const FontWeight = {
  Normal: 'normal',
  Medium: 'medium',
  Bold: 'bold',
};

export const FontColor = {
  Neutral01: 'neutral01',
  Neutral02: 'neutral02',
  Neutral03: 'neutral03',
  Neutral04: 'neutral04',
  Neutral05: 'neutral05',
  Neutral06: 'neutral06',
  Primary: 'primary',
  Utility: 'utility',
  Success: 'success',
};

export function Typography({
  component: Component,
  children,
  defaultStyle,
  typographyStyle,
  hasLineThrough,
  isCentered,
  fontWeight,
  color,
  isSmall,
  hasBorderTop,
  hasBorder,
  hasError,
  hasSuccess,
  hasWordBreak,
  forceMobileView,
  className: givenClassName,
  ...props
}: TypographyProps) {
  const styling = typographyStyle
    ? styles[typographyStyle]
    : styles[defaultStyle];

  const colorStyle = styles[typeof color === 'string' ? color : ''];
  const fontWeightStyle =
    styles[typeof fontWeight === 'string' ? fontWeight : ''];
  const className = classNames(
    styling,
    {
      [colorStyle]: !!colorStyle,
      [fontWeightStyle]: !!fontWeightStyle,
      [styles['line-through']]: hasLineThrough,
      [styles.small]: isSmall,
      [styles.border]: hasBorderTop,
      [styles.hasBorder]: hasBorder,
      [styles.centered]: isCentered,
      [styles.error]: hasError,
      [styles.success]: hasSuccess,
      [styles['word-break']]: hasWordBreak,
      [styles.forceMobileView]: forceMobileView,
    },
    givenClassName,
  );
  return (
    <Component {...props} className={className}>
      {children}
    </Component>
  );
}
